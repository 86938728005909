import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { GoogleLogin, useGoogleLogin } from 'react-google-login';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import AppleLogin from 'react-apple-login';
import AppleIcon from '@material-ui/icons/Apple';

import GoogleIcon from 'components/icons/googleSvg';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
const REACT_APP_APPLE_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI;
const REACT_APP_MODE_ENV = process.env.REACT_APP_MODE_ENV;
const PROVIDERS = {
	GOOGLE: 'Google',
	APPLE: 'Apple'
};

const useStyles = makeStyles({
	btnGoogle: {
		fontFamily: 'roboto-medium',
		backgroundColor: '#4285F4',
		color: '#fff',
		border: '1px solid transparent',
		fontWeight: 500,
		boxShadow:
			'0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);',
		'&:active': {
			backgroundColor: '#4285F4',
			color: '#fff'
		}
	},
	btnApple: {
		backgroundColor: '#ffffff',
		color: '#43484d',
		border: '1px solid transparent',
		fontWeight: 500,
		boxShadow:
			'0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);',
		'&:active': {
			backgroundColor: '#43484d',
			color: '#ffffff'
		}
	},
	disabled: {
		backgroundColor: '#d7d7d7',
		color: 'rgb(173 173 173)',
		cursor: 'not-allowed',
		'&:active': {
			backgroundColor: '#cdcdcd',
			color: 'rgb(173 173 173)'
		}
	}
});

function SignSocial({ origin, onSignSocial, onFailure }) {
	const router = useRouter();

	const { loaded: loadedGoogle } = useGoogleLogin({
		clientId: GOOGLE_CLIENT_ID,
		cookiePolicy: 'single_host_origin'
	});

	const isSignIn = useMemo(() => router.route.includes('signIn'), [router]);

	const arrOrigin = [
		'join',
		'sos',
		'affiliate',
		'quotation',
		'ally',
		'quick-ally'
	];

	const descriptionBtn = useMemo(() => {
		let name = '';

		if (arrOrigin.includes(origin)) name = 'Registrarse con ';
		else name = 'Iniciar sesión con ';

		if (isSignIn && arrOrigin.includes(origin)) name = 'Iniciar sesión con ';

		return name;
	}, [origin, isSignIn]);

	return (
		<div className={`${origin === 'join' && 'p-3'}`}>
			<GoogleLogin
				clientId={GOOGLE_CLIENT_ID}
				buttonText={`${descriptionBtn} ${PROVIDERS.GOOGLE}`}
				onSuccess={(res) => onSignSocial(res, false)}
				onFailure={onFailure}
				cookiePolicy="single_host_origin"
				uxMode="popup"
				className="flex justify-center w-full mb-4 text-sm"
				render={({ onClick }) => (
					<PureBtn
						descriptionBtn={descriptionBtn}
						onClick={onClick}
						type={PROVIDERS.GOOGLE}
						loaded={loadedGoogle}
					/>
				)}
			/>

			<AppleLogin
				clientId={APPLE_CLIENT_ID}
				redirectURI={REACT_APP_APPLE_REDIRECT_URI}
				responseType="code"
				responseMode="query"
				usePopup={true}
				scope="email name"
				state="initial"
				nonce="nonce"
				callback={(response) => {
					if (REACT_APP_MODE_ENV === 'dev') {
						alert(
							'Login o registro con apple no funciona en entornos de localhost.'
						);
						console.log(
							'Login o registro con apple no funciona en entornos de localhost.'
						);
					}

					if (response?.error?.hasOwnProperty('error')) {
						onFailure();
						return;
					}

					onSignSocial(response, false, true);
				}}
				render={({ onClick }) => (
					<PureBtn
						descriptionBtn={descriptionBtn}
						onClick={onClick}
						type={PROVIDERS.APPLE}
					/>
				)}
			/>
		</div>
	);
}

const PureBtn = ({ descriptionBtn, onClick, type, loaded = true }) => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = React.useState(false);

	// desactivate loader in error case
	React.useEffect(() => {
		const interval = setTimeout(() => {
			setIsLoading(true);
		}, 3000);

		return () => clearInterval(interval);
	}, []);

	return (
		<button
			className={`${
				type === 'Apple'
					? classes.btnApple
					: loaded
					? classes.btnGoogle
					: classes.disabled
			} justify-center w-full mb-4 text-base items-center inline-flex p-0 rounded-sm`}
			onClick={onClick}
			type="button"
			disabled={!loaded}
		>
			<div className="rounded-sm mr-1 sm:mr-3 p-1 sm:p-3">
				{type === 'Apple' ? <AppleIcon /> : <GoogleIcon />}
			</div>
			<span className="sm:pr-3 text-xs md:text-base pl-0 pt-3 pb-3 relative">
				{`${descriptionBtn} ${type}`}
			</span>
			{!loaded && !isLoading && (
				<CircularProgress color="primary" size={12} className="ml-3 md:ml-0" />
			)}
		</button>
	);
};

SignSocial.propTypes = {
	origin: PropTypes.string.isRequired,
	onSignSocial: PropTypes.func.isRequired,
	onFailure: PropTypes.func.isRequired
};

export default SignSocial;
